<template>
  <div class="recordBox">
    <div class="recordBoxTop">
      <div class="stepsBox">
        <div class="title" :style="{ color: itemConfig.create_time != 0 ? '#409EFF' : '#969699' }">提交申请</div>
        <div class="outSize" :style="{ background: itemConfig.create_time != 0 ? '#ddddfd' : '#F0F0F5' }">
          <div class="within" :style="{ background: itemConfig.create_time != 0 ? '#409EFF' : '#C8C8CC' }">1</div>
        </div>
        <div v-if="itemConfig.create_time != 0" class="time">{{ itemConfig.create_time}}</div>
      </div>
      <p class="xian"></p>
      <div class="stepsBox">
        <div class="title" :style="{ color: itemConfig.audit_time != 0 || itemConfig.reject_time != 0 ? '#409EFF' : '#969699' }">商家审核</div>
        <div class="outSize" :style="{ background: itemConfig.audit_time != 0 || itemConfig.reject_time != 0 ? '#ddddfd' : '#F0F0F5' }">
          <div class="within" :style="{ background: itemConfig.audit_time != 0 || itemConfig.reject_time != 0 ? '#409EFF' : '#C8C8CC' }">2</div>
        </div>
        <div class="time" v-if="itemConfig.audit_time != 0">{{ itemConfig.audit_time | dealDate }}</div>
        <div class="time" v-if="itemConfig.reject_time != 0">{{ itemConfig.reject_time | dealDate }}</div>
      </div>
      <p class="xian"></p>
      <div class="stepsBox">
        <div class="title" :style="{ color: itemConfig.remit_time != 0 ? '#409EFF' : '#969699' }">商家打款</div>
        <div class="outSize" :style="{ background: itemConfig.remit_time != 0 ? '#ddddfd' : '#F0F0F5' }">
          <div class="within" :style="{ background: itemConfig.remit_time != 0 ? '#409EFF' : '#C8C8CC' }">3</div>
        </div>
        <div class="time" v-if="itemConfig.remit_time != 0">{{ itemConfig.remit_time | dealDate }}</div>
      </div>
      <p class="xian"></p>
      <div class="stepsBox">
        <div class="title" :style="{ color: itemConfig.remit_time != 0 ? '#409EFF' : '#969699' }">提现完成</div>
        <div class="outSize" :style="{ background: itemConfig.remit_time != 0 ? '#ddddfd' : '#F0F0F5' }">
          <div class="within" :style="{ background: itemConfig.remit_time != 0 ? '#409EFF' : '#C8C8CC' }">4</div>
        </div>
        <div class="time" v-if="itemConfig.remit_time != 0">{{ itemConfig.remit_time | dealDate }}</div>
      </div>
    </div>
    <div class="recordStatusBox">
      <p>流程进度：{{ itemConfig.status == 0 ? '已驳回' : itemConfig.status == 1 ? '待审核' : itemConfig.status == 2 ? '待结算' : '已结算' }}</p>
      <p>提现单号：{{ itemConfig.withdraw_no }}</p>
    </div>
    <div class="recordCard">
      <div class="cardBox">
        <div class="top">申请金额（可结算金额）</div>
        <div class="bot">
          <div style="font-size: 24px; color: #409EFF">
            <b>￥{{ itemConfig.withdrawal_amount }}</b>
          </div>
          <div style="font-size: 12px; color: #969699">订单金额：￥{{ itemConfig.amount }}</div>
        </div>
      </div>
      <div class="cardBox">
        <div class="top">申请的订单商品总额</div>
        <div class="bot">
          <div style="font-size: 24px; color: #409EFF">
            <b>￥{{ itemConfig.goods_amount }}</b>
          </div>
          <div style="font-size: 12px; color: #969699">订单数量：{{ itemConfig.order_count }}</div>
        </div>
      </div>
      <div class="cardBox">
        <div class="top">店铺抽成</div>
        <div class="bot">
          <div style="font-size: 24px; color: #409EFF">
            <b>{{ itemConfig.rate }}%</b>
          </div>
          <div style="font-size: 12px; color: #969699">抽成金额：￥{{ itemConfig.commission }}</div>
        </div>
      </div>
    </div>
    <div class="recordTable">
      <div class="top">
        <div class="title">流水信息</div>
        <div class="search">
          <el-input clearable v-model.trim="searchNum" placeholder="请输入订单编号"></el-input>
          <el-button type="primary">搜索</el-button>
          <el-button>导出</el-button>
        </div>
      </div>
      <div class="bot">
        <el-table :data="tableList" style="width: 100%" :header-cell-style="{ 'background-color': '#E8EEF1' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column align="center" prop="pay_sn" label="订单编号"> </el-table-column>
          <el-table-column align="center" prop="amount" label="订单金额">
            <template v-slot="{ row }">
              ￥{{ row.amount }}
              <el-popover placement="top-start" width="350" trigger="hover">
                <template>
                  <div class="tipsBox">
                    <p>
                      订单商品总额：<span>￥{{ row.goods_amount }}</span>
                    </p>
                    <p>
                      余额抵扣金额：<span>￥{{ row.discount_reduction_amount }}</span>
                    </p>
                    <p>
                      快递金额：<span>￥{{ row.freight_fee }}</span>
                    </p>
                    <p>
                      会员折扣金额：<span>￥{{ row.pd_amount }}</span>
                    </p>
                    <p>
                      订单改价金额：<span>￥{{ row.revise_amount }}</span>
                    </p>
                    <p>
                      优惠券金额：<span>￥{{ row.reduction_amount }}</span>
                    </p>
                    <p>
                      运费改价金额：<span>￥{{ row.revise_freight_fee }}</span>
                    </p>
                    <p>
                      实际支付金额：<span>￥{{ row.amount }}</span>
                    </p>
                  </div>
                </template>
                <i slot="reference" style="color: #409EFF; cursor: pointer; margin-left: 10px" class="iconfont icon-tuoyuankaobei"></i>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="rake" label="抽成费率"> </el-table-column>
          <el-table-column align="center" prop="commission" label="抽成费用"> </el-table-column>
          <el-table-column align="center" prop="settlement_amount" label="可结算金额"> </el-table-column>
        </el-table>
        <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
      </div>
    </div>
    <div class="footer">
      <div class="footerBox" v-if="itemConfig.status == 1">
        <el-button type="primary" @click="Approved">通过审核</el-button>
        <el-button style="color: #409eff" @click="dialogVisibleReject = true">申请驳回</el-button>
      </div>
      <div class="footerBox" v-if="itemConfig.status == 0">
        <span style="color: #409EFF">驳回原因：</span>
        <span>{{ itemConfig.remark }}</span>
      </div>
      <div class="footerBox" v-if="itemConfig.status == 2">
        <el-button type="primary" @click="dialogVisiblePayment = true">确认打款</el-button>
      </div>
    </div>
    <!-- 驳回弹框 -->
    <el-dialog title="驳回" :visible.sync="dialogVisibleReject" width="52%">
      <div class="rejectBox">
        <div class="title">驳回原因：</div>
        <el-input v-model="rejectResult" type="textarea" :rows="7" placeholder="请添加驳回的订单号，帮助商家确认"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleReject = false">取 消</el-button>
        <el-button type="primary" @click="sureReject">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 确认打款弹框 -->
    <el-dialog title="确认打款" :visible.sync="dialogVisiblePayment" width="52%">
      <div class="paymentBox">
        <div class="paymentItem">
          <p class="title">账户类型：</p>
          <div class="right">
            {{ itemConfig.account_type == 1 ? '对公账户' : itemConfig.account_type == 2 ? '对私账户' : '微信' }}
          </div>
        </div>
        <div class="paymentItem" v-if="itemConfig.account_type != 3">
          <p class="title">开户行：</p>
          <div class="right">
            {{ itemConfig.bank }}
          </div>
        </div>
        <div class="paymentItem" v-if="itemConfig.account_type != 3">
          <p class="title">银行卡号：</p>
          <div class="right">
            {{ itemConfig.bank_card }}
          </div>
        </div>
        <div class="paymentItem" v-if="itemConfig.account_type == 3">
          <p class="title">微信号：</p>
          <div class="right">
            {{ itemConfig.openid }}
          </div>
        </div>
        <div class="paymentItem">
          <p class="title">*打款金额：</p>
          <div class="right">
            <el-input v-model="remit_amount"></el-input>
          </div>
        </div>
        <div class="paymentItem">
          <p class="title">*打款凭证：</p>
          <div class="right">
            <div class="imgBox" v-show="imgUrl != ''">
              <img :src="imgUrl" alt="" />
            </div>
            <el-upload
              accept=".jpg,.jpeg,.png,.bmp,.JPG,.JPEG,.PBG"
              :headers="headers"
              action="/admin/Upload/addFiles"
              :show-file-list="false"
              :on-progress="progress"
              name="files"
              :on-success="handleAvatarSuccess"
            >
              <div v-if="loading" v-loading="loading" class="loadingMaskBox"></div>
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div class="paymentItem">
          <p class="title">*备注：</p>
          <div class="right">
            <el-input v-model="dkremark" type="textarea"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePayment = false">取 消</el-button>
        <el-button type="primary" @click="payment">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDateformat } from '../../util/getDate'
import Paging from '@/components/paging'
export default {
  components: {
    Paging
  },
  data() {
    return {
      loading: false,
      headers: {
        'access-token': localStorage.getItem('token')
      },
      imgUrl: '',
      remit_amount: 0.0,
      dialogVisiblePayment: false,
      rejectResult: null,
      dkremark: null,
      dialogVisibleReject: false,
      itemConfig: {},
      searchNum: null,
      tableList: [],
      page: 1,
      pageNum: 10,
      total: 0
    }
  },
  created() {
    if (this.$route.query.info) {
      this.getConfig()
    }
    this.getTableList()
  },
  methods: {
    getConfig() {
      let info = JSON.parse(this.$route.query.info);
      this.itemConfig = info;
    },
    progress() {
      this.loading = true
    },
    handleAvatarSuccess(res, file) {
      this.imgUrl = file.response.result.path
      this.loading = false
    },
    // 确认打款
    payment() {
      if (this.imgUrl == '') {
        this.$message.warning('请上传凭证')
        return
      }
      let that = this
      let obj = {
        id: this.$route.query.id,
        status: 3,
        voucher: this.imgUrl,
        remit_amount: this.remit_amount
      }
      let flag = this.orderAudit(obj)
      console.log(flag, '审核执行结果')
      if (flag) {
        this.dialogVisiblePayment = false
      }
    },
    // 通过审核
    Approved() {
      let that = this
      this.$confirm('确认通过审核吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let obj = {
            id: this.$route.query.id,
            status: 2
          }
          that.orderAudit(obj)
        })
        .catch()
    },
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val
      } else {
        this.page = val
      }
      this.getTableList()
    },
    // 获取订单列表
    getTableList() {
      let that = this
      this.$axios
        .post(this.$api.finance.withdrawnList, {
          page: that.page,
          rows: that.pageNum,
          id: this.itemConfig.id,
        })
        .then((res) => {
          if (res.code == 0) {
            that.tableList = res.result.list
          }
        })
    },
    // 审核函数
    orderAudit(obj) {
      let that = this
      this.$axios.post(this.$api.finance.upStatus, obj).then((res) => {
        if (res.code == 0) {
          that.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getConfig()
          return true
        } else {
          that.$message({
            message: `${res.msg}`,
            type: 'error'
          })
        }
      })
    },
    // 确认驳回
    sureReject() {
      if (this.rejectResult == '') {
        this.$message({
          message: '请填写驳回原因',
          type: 'warning'
        })
        return
      }
      let obj = {
        id: this.itemConfig.id,
        status: -1,
        remark: this.rejectResult
      }
      let flag = this.orderAudit(obj)
      if (flag) {
        this.rejectResult = null
        this.dialogVisibleReject = false
      }
    }
  },
  filters: {
    dealDate(index) {
      return getDateformat(index)
    }
  }
}
</script>

<style lang="less" scoped>
.recordBox {
  width: 100%;
  font-size: 14px;
  background-color: #fff;
  padding: 20px;
  .recordBoxTop {
    width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    .stepsBox {
      position: relative;
      .title {
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
      }
      .outSize {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .within {
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          border-radius: 50%;
          color: #fff;
          font-weight: bold;
        }
      }
      .time {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -25px;
        color: #969699;
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
  .recordStatusBox {
    width: 1200px;
    height: 132px;
    margin: 0 auto;
    background-color: #f0f0f5;
    margin-top: 40px;
    color: #17171a;
    padding-top: 33px;

    p {
      text-align: center;
    }
    p:first-child {
      margin-bottom: 26px;
      font-size: 16px;
      font-weight: bolder;
    }
  }
  .recordCard {
    width: 1100px;
    display: flex;
    justify-content:space-around;
    margin: 0 auto;
    margin-top: 50px;
    .cardBox {
      width: 188px;
      height: 182px;
      border: 1px solid #ccc;
      border-radius: 8px;
      text-align: center;
      .top {
        height: 56px;
        background-color: #ddddfd;
        line-height: 56px;
        color: #409EFF;
      }
      .bot {
        height: calc(100% - 56px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }
  .recordTable {
    width: 1200px;
    margin: 0 auto;
    margin-top: 88px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 18px;
        color: #17171a;
        font-weight: bold;
      }
      .search {
        display: flex;
        align-items: center;
        .el-input {
          width: 400px;
          margin-right: 24px;
        }
      }
    }
    .bot {
      margin-top: 24px;
      .el-table {
        /deep/ .el-table__body-wrapper {
          height: 500px;
          overflow: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}

.xian {
  width: 100%;
  height: 2px;
  background-color: #f0f0f5;
  margin: 20px 0;
}
.tipsBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    width: 48%;
    span {
      color: #fd5e94;
    }
  }
}
.rejectBox {
  display: flex;
  .title {
    width: 120px;
  }
}
.footer {
  margin-top: 10px;
}
.footerBox {
  width: 1200px;
  margin: 0 auto;
}
.paymentBox {
  .paymentItem {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    p {
      width: 120px;
      text-align: right;
      margin-right: 20px;
    }
    .right {
      display: flex;
      align-items: center;
      .imgBox {
        width: 178px;
        height: 178px;
        overflow: hidden;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .el-input,
      .el-textarea {
        width: 400px;
      }
      /deep/ .el-upload {
        width: 178px;
        height: 178px;
        text-align: center;
        line-height: 178px;
        font-size: 28px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        .loadingMaskBox {
          width: 100%;
          height: 100%;
          .el-loading-mask {
            background-color: rgba(0, 0, 0, 0.2) !important;
            .el-loading-spinner {
              margin-top: 0 !important;
              top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
